import request from '@/utils/request'
import qs from 'qs'

// 列表
export const getProductList = params => {
  return request({
    method: 'GET',
    url: '/products',
    params
  })
}

// 删除
export const deleteProduct = id => {
  return request({
    method: 'DELETE',
    url: `/products/${id}`
  })
}

// 新增
export const addProduct = data => {
  return request({
    method: 'POST',
    url: '/products',
    data
  })
}

// 编辑
export const updateProduct = (id, data) => {
  return request({
    method: 'PATCH',
    url: `/products/${id}`,
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data)
      }
    ]
  })
}

// 导出
export const exportAll = () => {
  return request({
    method: 'POST',
    url: '/products/export',
    responseType: 'blob'
  })
}
