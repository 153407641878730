<template>
  <div class="product">
    <div class="form">
       <el-form :inline="true" size="small">
        <el-form-item label="商品标题">
          <el-input v-model="queryInfo.title" placeholder="请输入商品标题" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getProductList">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
        <el-button size="small" type="primary" @click="exportAll">导出</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 240px)"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column label="标题" prop="title" show-overflow-tooltip />
      <el-table-column label="封面" width="200">
        <template slot-scope="scope">
          <el-image
            v-for="(cover, index) in scope.row.covers"
            :key="index"
            class="cover"
            :src="cover"
            :preview-src-list="scope.row.covers"
            style="margin-right: 5px"
          />
        </template>
      </el-table-column>
      <el-table-column label="原价" prop="original_price" width="100" />
      <el-table-column label="特价" prop="promotion_price" width="100" />
      <el-table-column label="服务分类" prop="service_classify" show-overflow-tooltip />
      <el-table-column label="服务次数" prop="service_count" width="100" />
      <el-table-column label="服务方式" prop="service_mode" show-overflow-tooltip />
      <el-table-column label="是否推荐" prop="recommend" :formatter="recommendFormatter" />
      <el-table-column label="创建时间" prop="created_at" width="180" />
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
          />
          <el-button
            icon="el-icon-delete"
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="pageSizes"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 模态 -->
    <el-dialog :title="formTitle" width="70%" :visible.sync="productFormVisible">
      <el-form
        :model="productForm"
        :rules="productRules"
        ref="productForm"
        size="medium"
        label-width="80px"
      >
        <el-form-item label="标题" prop="title">
          <el-input v-model="productForm.title" placeholder="请输入商品标题" />
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input type="textarea" v-model="productForm.description" placeholder="请输入商品描述" />
        </el-form-item>
        <el-form-item label="封面" prop="covers">
          <div class="content">
            <div
              v-for="(cover, index) in covers"
              :key="index"
              class="preview-img"
            >
              <el-image
                class="cover"
                :src="cover"
                :preview-src-list="covers"
              />
              <el-button size="mini" icon="el-icon-delete" @click="deleteCover(index)" />
            </div>
            <image-upload
              style="width: 50px; height: 50px"
              dir="product"
              :play-back="false"
              @input="handleImageInput"
            />
          </div>
        </el-form-item>
        <el-form-item label="原价" prop="original_price">
          <el-input v-model="productForm.original_price" placeholder="请输入商品原价" />
        </el-form-item>
        <el-form-item label="特价" prop="promotion_price">
          <el-input v-model="productForm.promotion_price" placeholder="请输入商品特价" />
        </el-form-item>
        <el-form-item label="服务分类" prop="service_classify">
          <el-input v-model="productForm.service_classify" placeholder="请输入服务分类" />
        </el-form-item>
        <el-form-item label="服务次数" prop="service_count">
          <el-input v-model="productForm.service_count" placeholder="请输入服务次数" />
        </el-form-item>
        <el-form-item label="服务方式" prop="service_mode">
          <el-input v-model="productForm.service_mode" placeholder="请输入服务方式" />
        </el-form-item>
        <el-form-item label="推荐商品" prop="recommend">
          <el-switch
            v-model="productForm.recommend"
            active-value="1"
            inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <quill-editor
            v-model="productForm.content"
            :options="editorOption"
            ref="myQuillEditor"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
  getProductList,
  deleteProduct,
  addProduct,
  updateProduct,
  exportAll
} from '@/services/product'

Quill.register('modules/ImageExtend', ImageExtend)

export default {
  name: 'Product',
  components: {
    quillEditor
  },
  data () {
    return {
      tableData: [],
      total: 0,
      pageSizes: [10, 20, 30, 40, 50, 100],
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      productFormVisible: false,
      productForm: {},
      productRules: {},
      formTitle: '新增',
      formType: 'add',
      covers: [],
      editorOption: {
        placeholder: '请输入内容...',
        modules: {
          ImageExtend: {
            loading: true,
            name: 'img',
            action: `${process.env.VUE_APP_SERVER}/api/v1/third/image-to-cos`,
            headers: (xhr) => {
              xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.user.access_token}`)
            },
            response: (res) => {
              return `${process.env.VUE_APP_COS_URL}/${res.key}`
            }
          },
          toolbar: {
            container: container,
            handlers: {
              image () {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      }
    }
  },
  created () {
    this.getProductList()
  },
  methods: {
    async getProductList () {
      const { data } = await getProductList(this.queryInfo)
      this.tableData = data.data
      this.total = data.meta.total
    },
    recommendFormatter (row, column, cellValue, index) {
      return cellValue ? '是' : '否'
    },
    handleEdit (index, row) {
      if (this.$refs.productForm) {
        this.$refs.productForm.resetFields()
      }
      this.formTitle = '编辑'
      this.formType = 'edit'
      this.productForm = { ...row }
      this.covers = this.productForm.covers || []
      this.productFormVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('请确认操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteProduct(row.id)
        this.getProductList()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getProductList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNum = newPage
      this.getProductList()
    },
    reset () {
      this.queryInfo = {
        pageNum: 1,
        pageSize: 10
      }
      this.getProductList()
    },
    handleAdd () {
      if (this.$refs.productForm) {
        this.$refs.productForm.resetFields()
      }
      this.formTitle = '新增'
      this.formType = 'add'
      this.productFormVisible = true
    },
    handleImageInput (val) {
      this.covers.push(val)
    },
    cancel () {
      this.productForm = {}
      this.productFormVisible = false
    },
    save () {
      this.$refs.productForm.validate(async valid => {
        if (valid) {
          try {
            const formData = { ...this.productForm }
            formData.covers = this.covers
            this.formType === 'add' ? await addProduct(formData) : await updateProduct(formData.id, formData)
            this.productForm = {}
            this.covers = []
            this.productFormVisible = false
            this.getProductList()
            this.$message({
              type: 'success',
              message: `${this.formTitle}商品成功`
            })
          } catch (err) {
            this.$message({
              type: 'error',
              message: `${this.formTitle}商品失败`
            })
          }
        }
      })
    },
    deleteCover (index) {
      this.covers.splice(index, 1)
    },
    async exportAll () {
      const { data } = await exportAll()
      const blob = new Blob([data], { type: 'application/octet-stream,charset=UTF-8' })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `商品-${Date.now()}.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    }
  }
}
</script>

<style lang="scss">
.product {
  box-sizing: border-box;
  padding: 15px;
  .form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 15px 0;
    .el-form .el-form-item {
      margin-bottom: 0;
    }
  }
  .pagination {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
  .cover {
    margin-bottom: 5px;
    width: 50px;
    height: 50px;
  }
  .el-dialog {
    .content {
      display: flex;
      flex-wrap: wrap;
      .preview-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 5px;
        width: 50px;
      }
    }
    .ql-container {
      height: 400px;
    }
  }
}
</style>
